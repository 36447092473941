import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAlert } from 'shared/hooks'
import { selectors } from 'shared/store'
import { LoadingStatus } from 'shared/types'
import { getLogoutError } from 'shared/utils'
import paths from '../../paths'
import LoadingSpinner from '../LoadingSpinner'
import {
  FormTextWrapperGrid,
  FormTitle,
  FormWrapper,
  StyledTextField,
  ForgetPasswordLink,
} from './LoginForm.style'
import { useLoginForm } from './LoginForm.utils'
import { constants } from 'config'

const LoginForm = () => {
  const { t } = useTranslation()
  const formProps = useLoginForm()
  const isProcessing = useSelector(selectors.auth.getLoadingState)
  const errorAlert = useAlert('error')

  useEffect(() => {
    const error = getLogoutError()
    if (error) errorAlert(t('loginForm.loggedOutInformation', { error }))
  }, [])

  return (
    <Grid container>
      <FormWrapper>
        <FormTextWrapperGrid item xs={12}>
          <Box color="primary.light">
            <FormTitle variant="h1" align="center">
              {t('loginForm.title')}
            </FormTitle>
          </Box>
          <Box color="primary.light">
            <Typography variant="body2" align="center">
              {t('loginForm.subtitle')}
            </Typography>
          </Box>
        </FormTextWrapperGrid>
        <Formik {...formProps}>
          {({ isValid, isSubmitting, dirty }) => (
            <Form>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Field
                    component={StyledTextField}
                    placeholder={t('loginForm.username')}
                    name="username"
                    type="text"
                    autoComplete="username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={StyledTextField}
                    placeholder={t('loginForm.password')}
                    name="password"
                    type="password"
                    autoComplete="password"
                  />
                  <ForgetPasswordLink
                    href={paths.forgetPassword}
                    target="blank"
                    underline="none"
                    variant="h6"
                  >
                    {t('loginForm.forgetPassword')}
                  </ForgetPasswordLink>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting || !dirty}
                    color="secondary"
                    fullWidth
                  >
                    <LoadingSpinner
                      isVisible={isProcessing === LoadingStatus.Pending}
                    >
                      {t('loginForm.submit')}
                    </LoadingSpinner>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center">
                    {t('loginForm.reportIssues')}{' '}
                    <a href={`mailto:${constants.avireMail}`}>
                      {constants.avireMail}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Grid>
  )
}

export default LoginForm
