import { Position } from 'react-rnd'
import { Size, WidgetType } from 'shared/types'

export const DEFAULT_WIDGET_SIZE: Size<string, string> = {
  width: '100%',
  height: '100%',
}
export const MAX_TEMPLATE_NAME_LENGTH = 40
export const DEFAULT_WIDGET_POSITION: Position = { x: 0, y: 0 }
export const MAX_COLOR_LENGTH = 7
export const DEFAULT_CATEGORY = 'Unknown'
export const DEFAULT_WIDGET_NAME = 'No Name Attached'
export const ALIGNMENT_ERROR_MARGIN = 0.999
export const DEFAULT_LIVEVIDEO_SIZE = { width: 200, height: 200 }
export const MIN_WIDTH_CLEARABLE_FIELD = 132
export const LAYOUT_FORM_HEIGHT = 130
export const MIN_SIZE = 25
export const DIRECTION_ARROW_WIDGET_MIN_SIZE = 152 // it is converted to 40mm
export const VERTICAL_SPACING = 40
export const HORIZONTAL_SPACING = 40
export const MAX_FLIPPING_IMAGES_COUNT = 16
export const MIN_FLIPPING_IMAGES_COUNT = 2
export const MAX_FLIPPING_IMAGES_TRANSITION_TIME = 12
export const MIN_FLIPPING_IMAGES_TRANSITION_TIME = 1
export const TILE_TOOLBAR_SIZE = 30
export const DEFAULT_TRANSITION_TIME = 3000
export const MAX_WIDGET_NAME_LENGTH = 30
export const DEFAULT_RSS_FEED_WIDGET_SIZE = 200
export const DEFAULT_RSS_FEED_PREVIEW_TEXT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis risus vel malesuada vehicula. Aliquam.'
export const DEFAULT_TILE_WIDGET_WIDTH = 127
export const DEFAULT_TEXT_WIDGET_COLOR = '#000000'
export const DEFAULT_TEXT_SIZE = 16
export const TILE_ICON_SIZE = 65
export const DEFAULT_CATEGORY_NAME = 'No category'
export const LIFT_INFORMATION_WIDGETS = [
  WidgetType.DirectionArrows,
  WidgetType.ImagesByFloor,
  WidgetType.LiftStatus,
  WidgetType.Pictogram,
  WidgetType.PositionIndicatorText,
]
export const MIN_PICTOGRAMS_COUNT = 1
export const COLOR_WHITE = '#ffffff'
export const COLOR_BLACK = '#000000'
export const DateTimeFormatOptions = [
  { value: 'dd-MM-YY', label: 'Day/Month/Year [DD/MM/YY]' },
  { value: 'dd-MMM-YYYY', label: 'Day/Month/Year [DD/MMM/YYYY]' },
  { value: 'dd-MMMM-YYYY', label: 'Day/Month/Year [DD/MMMM/YYYY]' },
  { value: 'MM-dd-YYYY', label: 'Month, Day, Year [MM/DD/YYYY]' },
  { value: 'MMM-dd-YYYY', label: 'Month, Day, Year [MMM/DD/YYYY]' },
  { value: 'MMMM-dd-YYYY', label: 'Month, Day, Year [MMMM/DD/YYYY]' },
  { value: 'hh:mm:ss AM/PM', label: 'HH:MM:SS AM/PM' },
  { value: 'hh:mm AM/PM', label: 'HH:MM AM/PM' },
  { value: 'HH:mm:ss', label: 'HH:MM:SS' },
  { value: 'HH:mm', label: 'HH:MM' },
]
export const ZOOM_VARIANTS = [25, 50, 75, 100, 125, 150, 200]
