import { styled, Drawer as BaseDrawer, Theme, Grid } from '@material-ui/core'
import { Logo } from 'shared/icons'
import { DRAWER_WIDTH } from 'shared/config'

export const BrandLogo = styled(Logo)(({ theme }) => ({
  padding: theme.spacing(6, 20, 3, 4.5),
  width: '100%',
  height: 'auto',
}))

interface DrawerProps {
  zIndex: number
}

export const Drawer = styled(BaseDrawer)<Theme, DrawerProps>(
  ({ theme, zIndex }) => ({
    width: DRAWER_WIDTH,
    zIndex,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH,
      background: theme.palette.accent.main,
      color: theme.palette.primary.contrastText,
    },
  })
)

export const Container = styled('div')({
  display: 'flex',
  minHeight: '100vh',
})

export const GridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  marginBottom: theme.spacing(5),
}))
