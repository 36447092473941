import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Logo = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 112 30" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.0571 14.7697C75.9673 15.0045 73.8302 14.8193 71.6158 14.8193V5.5533C73.9044 5.67906 76.1702 5.61628 78.3664 5.97708C80.5785 6.3406 81.8609 8.20255 81.8225 10.3304C81.781 12.6153 80.3531 14.5114 78.0571 14.7697ZM84.9835 17.2323C87.3322 14.9648 87.8959 12.0745 87.6364 8.96639C87.4332 6.53417 86.498 4.42419 84.5981 2.81104C82.5676 1.08725 80.1557 0.350533 77.5557 0.286976C73.8446 0.196291 70.1303 0.234076 66.4179 0.193965C65.5366 0.184471 65.3461 0.649716 65.3477 1.41124C65.367 10.0647 65.3701 18.718 65.3825 27.3714C65.3833 27.8762 65.424 28.381 65.4463 28.8948H71.5296C71.5296 28.6139 71.5298 28.4247 71.5296 28.2356C71.5263 25.8462 71.5472 23.4564 71.5048 21.0674C71.4918 20.3404 71.7241 20.0943 72.4447 20.1429C73.2483 20.1976 74.0672 20.2267 74.8634 20.1313C75.9014 20.0069 76.5077 20.4164 77.0517 21.2814C78.5249 23.6246 80.0768 25.9191 81.628 28.2124C81.8409 28.5273 82.2199 28.9351 82.5325 28.9443C84.7225 29.0078 86.9152 28.978 89.3155 28.978C86.9699 25.542 84.752 22.2927 82.5604 19.0826C83.4611 18.4033 84.297 17.8952 84.9835 17.2323Z"
      fill="currentColor"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="91"
      y="0"
      width="21"
      height="29"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.7695 0.20985H111.819V28.9855H91.7695V0.20985Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.663 23.5456C106.757 23.5456 102.851 23.5464 98.9441 23.5448C98.5014 23.5446 97.96 23.652 97.9547 22.9592C97.9398 20.969 97.9495 18.9784 97.9495 16.8512H108.509V12.3313H97.9565C97.9565 10.3618 98.0191 8.52694 97.9299 6.69948C97.8854 5.78275 98.2297 5.61668 99.0249 5.62405C102.866 5.65951 106.707 5.65563 110.548 5.66551C110.919 5.66629 111.289 5.66571 111.65 5.66571V0.313929C111.359 0.284864 111.139 0.243978 110.92 0.24359C104.883 0.232739 98.8459 0.233902 92.8089 0.209874C91.9961 0.20658 91.7676 0.547618 91.7695 1.3229C91.7912 10.0715 91.7854 18.8203 91.7897 27.5691C91.7903 28.9199 91.8614 28.9859 93.2097 28.9855C99.0205 28.984 104.831 28.9815 110.643 28.9793C111.02 28.9793 111.397 28.9793 111.819 28.9793V23.5456H110.663Z"
        fill="currentColor"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.58704 19.476C10.9894 15.296 12.3555 11.2242 13.7215 7.15252C13.8089 7.15058 13.8963 7.14845 13.9837 7.14651C15.3519 11.2235 16.7202 15.3006 18.1211 19.476H9.58704ZM20.8252 10.4823C19.7575 7.58308 18.6864 4.68522 17.6177 1.7864C17.0951 0.368766 16.8971 0.229444 15.3622 0.224212C14.7813 0.222468 14.1998 0.212586 13.6192 0.22925C10.815 0.309666 10.7541 -0.298196 9.75078 2.80641C9.66223 3.08041 9.51768 3.33599 9.41711 3.60688C6.59676 11.2118 3.77855 18.8176 0.961107 26.4235C0.64274 27.2833 0.331737 28.1455 0 29.0539H6.18054C6.69655 27.6462 7.24512 26.2733 7.69448 24.8685C7.89852 24.2308 8.19596 24.0289 8.85963 24.0347C12.2163 24.0637 15.5736 24.0568 18.9301 24.0188C19.539 24.0118 19.7895 24.2556 19.9651 24.7778C20.3852 26.0282 20.8105 27.2788 21.3067 28.4996C21.4141 28.7639 21.8233 29.0662 22.1016 29.0741C23.9062 29.1254 25.7131 29.1004 27.6264 29.1004C27.5603 28.8495 27.5384 28.7251 27.4954 28.6085C25.2732 22.5659 23.0501 16.5239 20.8252 10.4823Z"
      fill="currentColor"
    />
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="23"
      y="0"
      width="28"
      height="30"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8008 0.220491H50.8259V29.1258H23.8008V0.220491Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6504 0.246246C44.4665 0.249734 44.1964 0.547561 44.1224 0.762453C43.5463 2.43548 43.0084 4.12168 42.4589 5.804C40.9193 10.5161 39.3783 15.2279 37.843 19.9416C37.7184 20.3243 37.6314 20.7194 37.527 21.1087C37.4417 21.1011 37.3563 21.0937 37.271 21.0864C35.0064 14.1598 32.742 7.23326 30.4656 0.270661H23.8008C24.4675 2.16962 25.1066 4.0153 25.7635 5.85458C26.9108 9.06692 28.0736 12.2737 29.2219 15.4856C30.7054 19.635 32.1804 23.7876 33.6609 27.9381C33.9503 28.7499 34.4464 29.1763 35.393 29.1211C36.6794 29.0459 37.9748 29.0789 39.2647 29.1186C40.1452 29.1457 40.643 28.7303 40.9201 27.9453C41.2951 26.8833 41.6834 25.826 42.0597 24.7646C44.3617 18.274 46.6579 11.7815 48.9651 5.29283C49.5548 3.63492 50.177 1.98864 50.8259 0.222993C48.6995 0.222993 46.6746 0.209235 44.6504 0.246246Z"
        fill="currentColor"
      />
    </g>
    <mask
      id="mask2"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="112"
      height="30"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 29.1258H111.819V0.193771H0V29.1258Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.0508 29.0406H60.098V0.276125H54.0508V29.0406Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)

export default Logo
